import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {
  Alert,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import './TrackShipment.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { MetaTags } from 'react-meta-tags';
import { base_logo_url, base_url } from '../../apis/constants';
import { GetTrackShipment } from '../../apis/Agency';
import MaterialReactTable from 'material-react-table';

import NoLogo from '../../assets/carrier logos/No Logo.png';
import ViewShipment from '../ViewShipment/ViewShipment';
import { ToastContainer } from 'react-toastify';

export default function TrackShipmentModule(props) {
  const [shipmentData, setShipmentData] = React.useState([]);

  const [expandedDetail, setExpandedDetail] = React.useState(false);
  const [actualDeliveryDate, setActualDeliveryDate] = React.useState(false);

  const handleViewShipments = row => {
    setExpandedDetail(false);
    return (
      <Box>
        <ViewShipment
          rowData={row.original}
          disptachOpen={'View'}
          // handleTrackShipmentRequest={handleTrackShipmentRequest}
        />
      </Box>
    );
  };

  const shipmentStatusColor = shipmentStatus => {
    if (shipmentStatus === 'Saved') {
      return { color: '#448aff', backgroundColor: '#bbdefb' };
    }

    if (shipmentStatus.toUpperCase() === 'HOLD') {
      return { color: '#d50000', backgroundColor: '#ffebee' };
    }
    if (shipmentStatus === 'Booked') {
      return { color: '#9c27b0', backgroundColor: '#f3e5f5' };
    }
    if (shipmentStatus === 'Delivered') {
      return { color: '#1b5e20', backgroundColor: '#c8e6c9' };
    }
    if (shipmentStatus === 'InTransit') {
      return { color: '#e65100', backgroundColor: '#ffe0b2' };
    }
  };

  const handleDateTimeShow = (timeStamp, type) => {
    // Check if timeStamp is valid and defined
    if (!timeStamp || isNaN(Date.parse(timeStamp))) {
      return 'Invalid Date';
    }

    const dateTimeString = timeStamp + 'Z';
    const dateTime = new Date(dateTimeString);
    const date = new Date(dateTimeString);
    const bolDate = new Date(timeStamp);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const timeOptions = new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: userTimeZone
    }).format(dateTime);

    const dateOptions = {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    };

    const bolDateOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    };

    const formattedDate = date.toLocaleDateString(undefined, dateOptions);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const formattedBolDate = bolDate.toLocaleDateString('en-US', options);

    // Handle different types
    if (type === 'time') {
      return timeOptions;
    }
    if (type === 'date') {
      return formattedDate;
    }
    if (type === 'bol') {
      return formattedBolDate;
    }

    return 'Invalid Type';
  };

  const mtransitTabColumns = React.useMemo(
    () => [
      {
        // accessorFn: row => `${row.carrierName} ${row.carrierLogo}`,
        accessorKey: 'carrierName',
        id: 'carrierName',
        header: 'Carrier Name',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.carrierName
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            {renderedCellValue && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AvatarGroup sx={{}} spacing='small'>
                  <Avatar
                    sx={
                      {
                        // bgcolor: 'ButtonFace',
                        // objectFit: 'contain',
                        // mr: 1
                      }
                    }
                    imgProps={{
                      style: {
                        // height: 25
                      }
                    }}
                    // src={base_logo_url + carrier_logos/${items.logo}`}
                    src={
                      row.original.carrierLogo
                        ? base_logo_url + row.original.carrierLogo
                        : NoLogo
                    }
                  />
                </AvatarGroup>
                <span>{renderedCellValue}</span>
                <br />
              </Box>
            )}
          </>
        )
      },
      {
        // accessorFn: (row) => `${row.soNumber}|${row.clientPONumber}`,
        accessorKey: 'proNumber',
        id: 'proNumber',
        header: 'PRO/Tracking #',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.proNumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        // accessorFn: (row) => `${row.soNumber}|${row.clientPONumber}`,
        accessorKey: 'bolNumber',
        id: 'bolNumber',
        header: 'BOL #',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.bolNumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        // accessorFn: (row) => `${row.soNumber}|${row.clientPONumber}`,
        accessorKey: 'soNumber',
        id: 'soNumber',
        header: 'SO#',
        size: 10,
        filterFn: (row, colID, filterValue) => {
          return row.original.soNumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box>
              <span>{renderedCellValue}</span>
              {/* <br /> */}
            </Box>
          </>
        )
      },
      {
        //   // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
        accessorKey: 'shipmentStatus',
        id: 'shipmentStatus',
        header: 'Shipment Status',
        size: 50,
        // filterFn: (row, colID, filterValue) => {
        //   return row?.original?.shipmentStatus
        //     .toLowerCase()
        //     .includes(filterValue?.toLowerCase());
        // },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box>
              <Chip
                sx={shipmentStatusColor(row?.original?.shipmentStatus)}
                label={renderedCellValue}
              />
            </Box>
          </>
        )
      },

      {
        // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
        accessorKey: 'shipmentType',
        id: 'shipmentType',
        header: 'Shipment Mode',
        size: 70,
        filterFn: (row, colID, filterValue) => {
          return row.original.shipmentType
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Chip
                sx={{ backgroundColor: '#E1F5FE', color: '#01579B' }}
                label={renderedCellValue}
              />
            </Box>
          </>
        )
      },
      {
        // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
        accessorKey: 'quoteNumber',
        id: 'quoteNumber',
        header: 'Quote Number',
        size: 70,
        filterFn: (row, colID, filterValue) => {
          return row.original.quoteNumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {renderedCellValue}
            </Box>
          </>
        )
      },

      {
        accessorKey: 'pickupDate',
        id: 'pickupDate',
        header: (
          <div style={{ whiteSpace: 'nowrap' }}>Requested Pickup Date</div>
        ),
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.actualPickupDate
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },

        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        accessorKey: 'actualPickupDate',
        id: 'actualPickupDate',
        header: 'Actual Pickup Date',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.actualPickupDate
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },

        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        // accessorFn: (row) => `${row.soNumber}|${row.clientPONumber}`,
        accessorKey: 'deliveryDate',
        id: 'deliveryDate',
        header: 'Est Delivery Date',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.deliveryDate
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        accessorKey: 'actualDeliveryDate',
        id: 'actualDeliveryDate',
        header: <div style={{ flexWrap: 'nowrap' }}>Actual Delivery Date</div>,
        size: 10,
        Cell: ({ renderedCellValue, row }) => {
          if (new Date(renderedCellValue) < new Date()) {
            setActualDeliveryDate(true);
            return (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                    // borderRadius: '50%',
                    // backgroundColor: 'yellow'
                  }}
                >
                  <span>{renderedCellValue}</span>
                  <br />
                </Box>
              </>
            );
          } else {
            return (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <span>{renderedCellValue}</span>
                  <br />
                </Box>
              </>
            );
          }
        }
      },

      {
        accessorKey: 'originCompany',
        id: 'originCompany', //id is still required when using accessorFn instead of accessorKey
        header: 'Origin Company',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.originCompany
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {cell.getValue()}
            </Box>
          </>
        )
      },
      {
        accessorKey: 'originZipCode',
        id: 'originZipCode', //id is still required when using accessorFn instead of accessorKey
        header: 'Origin Zip',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.originZipCode
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {cell.getValue()}
            </Box>
          </>
        )
      },
      {
        accessorKey: 'originCity',
        id: 'originCity', //id is still required when using accessorFn instead of accessorKey
        header: 'Origin City',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.originCity
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {cell.getValue()}
            </Box>
          </>
        )
      },

      {
        accessorKey: 'originState',
        id: 'originState', //id is still required when using accessorFn instead of accessorKey
        header: 'Origin State',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.originState
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {cell.getValue()}
            </Box>
          </>
        )
      },
      {
        accessorKey: 'destinationCompany',
        id: 'destinationCompany', //id is still required when using accessorFn instead of accessorKey
        header: 'Destination Company',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.destinationCompany
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {cell.getValue()}
            </Box>
          </>
        )
      },
      {
        accessorKey: 'destinationZipCode',
        id: 'destinationZipCode', //id is still required when using accessorFn instead of accessorKey
        header: 'Destination Zip',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.destinationZipCode
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {cell.getValue()}
            </Box>
          </>
        )
      },
      {
        accessorKey: 'destinationCity',
        id: 'destinationCity', //id is still required when using accessorFn instead of accessorKey
        header: 'Destination City',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.destinationCity
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {cell.getValue()}
            </Box>
          </>
        )
      },
      {
        accessorKey: 'destinationState',
        id: 'destinationState', //id is still required when using accessorFn instead of accessorKey
        header: 'Destination State',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.destinationState
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {cell.getValue()}
            </Box>
          </>
        )
      },
      {
        accessorKey: 'billToCompany',
        id: 'billToCompany', //id is still required when using accessorFn instead of accessorKey
        header: 'Bill To Company ',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.billToCompany
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ cell }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {cell.getValue()}
            </Box>
          </>
        )
      },

      {
        // accessorFn: row => `${row.shipmentCharges}`,
        accessorKey: 'shipmentCharges',
        id: 'shipmentCharges',
        header: 'Carrier Total Rate',
        size: 50,
        // filterFn: (row, colID, filterValue) => {
        //   return row.original.shipmentCharges
        //     .toLowerCase()
        //     .includes(filterValue.toLowerCase());
        // },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>${parseFloat(renderedCellValue).toFixed(2)}</span>
              <br />
            </Box>
          </>
        )
      },

      {
        // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
        accessorKey: 'freightTerms',
        id: 'freightTerms',
        header: (
          <div style={{ whiteSpace: 'nowrap' }}>Carrier Freight Terms</div>
        ),
        size: 70,
        filterFn: (row, colID, filterValue) => {
          return row.original.freightTerms
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {renderedCellValue === 'C' ? (
                <Chip
                  sx={{ backgroundColor: '#E8F5E9', color: '#1B5E20' }}
                  label={renderedCellValue}
                />
              ) : renderedCellValue === 'PP' ? (
                <Chip
                  sx={{ backgroundColor: '#E1F5FE', color: '#01579B' }}
                  label={renderedCellValue}
                />
              ) : renderedCellValue === 'P' ? (
                <Chip
                  sx={{ backgroundColor: '#FCE4EC', color: '#B71C1C' }}
                  label={renderedCellValue}
                />
              ) : (
                <Chip
                  sx={{ backgroundColor: '#00000014', color: '#00000099' }}
                  label={renderedCellValue}
                />
              )}
            </Box>
          </>
        )
      },
      {
        // accessorFn: (row) => `${row.details[0].pallets}`,
        accessorKey: 'totalNetWeight',
        id: 'totalNetWeight',
        header: 'Total Net Weight',
        size: 50,
        // filterFn: (row, colID, filterValue) => {
        //   return row.original.customerId
        //     .toLowerCase()
        //     .includes(filterValue.toLowerCase());
        // },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },

      {
        // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
        accessorKey: 'paymentTerms',
        id: 'paymentTerms',
        header: (
          <div style={{ whiteSpace: 'nowrap' }}>Customer Freight Terms</div>
        ),
        size: 70,
        filterFn: (row, colID, filterValue) => {
          return row.original.paymentTerms
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },

      {
        // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
        accessorKey: 'shippingNotes',
        id: 'shippingNotes',
        header: 'Shipping Notes',
        size: 10,
        filterFn: (row, colID, filterValue) => {
          return row.original.shippingNotes
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {renderedCellValue.replace(/<\/?[^>]+(>|$)|&nbsp;/g, '').trim()}
              <br />
            </Box>
          </>
        )
      },
      {
        // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
        accessorKey: 'locationName',
        id: 'locationName',
        header: 'Location Name',
        size: 10,
        filterFn: (row, colID, filterValue) => {
          return row.original.locationName
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        // accessorFn: (row) => `${row.soNumber}|${row.clientPONumber}`,
        accessorKey: 'clientPONumber',
        id: 'clientPONumber',
        header: 'PO#',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.clientPONumber
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        // accessorFn: (row) => `${row.details[0].pallets}`,
        accessorKey: 'customerId',
        id: 'customerId',
        header: 'Customer ID',
        size: 50,
        filterFn: (row, colID, filterValue) => {
          return row.original.customerId
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <span>{renderedCellValue}</span>
              <br />
            </Box>
          </>
        )
      },
      {
        // accessorFn: (row) => `${row.shipmentType}|${row.shipmentStatus}`,
        accessorKey: 'shipmentTypeRule',
        id: 'shipmentTypeRule',
        header: 'Shipment Type',
        size: 70,
        filterFn: (row, colID, filterValue) => {
          return row.original.shipmentTypeRule
            .toLowerCase()
            .includes(filterValue.toLowerCase());
        },
        Cell: ({ renderedCellValue, row }) => (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {renderedCellValue}
            </Box>
          </>
        )
      }
    ],
    []
  );
  const [tableCol, setTableCol] = React.useState(mtransitTabColumns);

  const handleFilterdTableCols = apiRes => {
    console.log(apiRes.length, 'apiRes');
    const filteredCol = mtransitTabColumns.filter(colNames => {
      return !colNames.accessorKey.includes('mrt');
    });
    if (
      apiRes.length === 1 &&
      (apiRes[0]?.shipmentStatus === 'Saved' ||
        apiRes[0]?.shipmentStatus === 'Tendered' ||
        apiRes[0]?.shipmentStatus === 'Cancelled')
    ) {
      const col = filteredCol.filter(col => {
        return (
          col.accessorKey !== 'carrierName' &&
          col.accessorKey !== 'proNumber' &&
          col.accessorKey !== 'bolNumber' &&
          col.accessorKey !== 'shipmentCharges' &&
          col.accessorKey !== 'deliveryDate' &&
          col.accessorKey !== 'actualDeliveryDate' &&
          col.accessorKey !== 'actualPickupDate' &&
          col.accessorKey !== 'pickupDate' &&
          // col.accessorKey !== 'calculatedEstimatedDate' &&
          col.accessorKey !== 'quoteNumber'
        );
      });
      // setTimeout(() => {
      setTableCol(col);
      // }, 10);
    } else if (apiRes.length === 1 && apiRes[0]?.shipmentStatus === 'Booked') {
      const col = filteredCol.filter(col => {
        return (
          col.accessorKey !== 'proNumber' &&
          col.accessorKey !== 'bolNumber' &&
          // col.accessorKey !== 'calculatedEstimatedDate' &&
          col.accessorKey !== 'actualDeliveryDate' &&
          col.accessorKey !== 'actualPickupDate'
        );
      });
      // setTimeout(() => {
      setTableCol(col);
      // }, 10);
    } else if (
      apiRes.length === 1 &&
      apiRes[0]?.shipmentStatus === 'InTransit'
    ) {
      const col = filteredCol.filter(col => {
        return (
          // col.accessorKey !== 'deliveryDate' &&
          col.accessorKey !== 'pickupDate' &&
          col.accessorKey !== 'actualDeliveryDate'
        );
      });
      // setTimeout(() => {
      setTableCol(col);
      // }, 10);
    } else if (
      apiRes.length === 1 &&
      apiRes[0]?.shipmentStatus === 'Delivered'
    ) {
      const col = filteredCol.filter(col => {
        return (
          col.accessorKey !== 'pickupDate'
          // && col.accessorKey !== 'deliveryDate'
        );
      });
      // setTimeout(() => {
      setTableCol(col);
      // }, 10);
    } else {
      // setTimeout(() => {
      setTableCol(filteredCol);
      // }, 10);
    }
  };

  const [loadingShipment, setLoadingShipment] = React.useState(false);
  const [reffNumber, setReffNumber] = React.useState('');
  const [reffNumberError, setReffNumberError] = React.useState('');
  const [recordError, setRecordError] = React.useState('');

  const handleGetShipment = () => {
    if (reffNumber.trim() === '') {
      setReffNumberError('Please enter reference number.');
    } else {
      setLoadingShipment(true);
      setRecordError('');
      setShipmentData({});

      GetTrackShipment(reffNumber)
        .then(res => {
          setLoadingShipment(false);

          setShipmentData(res?.data?.data);

          handleFilterdTableCols(res?.data?.data);
          if (res?.data?.data.length === 0) {
            setRecordError('No shipment found for this reference number!');
          }

          console.log(res, 'trackres');
        })
        .catch(err => {
          setLoadingShipment(false);
          setRecordError('No shipment found for this reference number!');
        });
    }
  };

  return (
    <Box>
      <MetaTags>
        <title>
          {' '}
          {base_url === 'https://beta.api.shiptechpro.com/'
            ? 'Track Shipment | Shiptechpro'
            : 'Track Shipment | TMS Shipping'}
        </title>
      </MetaTags>

      <ToastContainer
        style={{ width: 'auto' }}
        position='top-center'
        autoClose={6800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {
        <div
          className='d-flex flex-column  py-4 px-2'
          style={{ maxWidth: '90vw' }}
        >
          <div
            className='d-flex justify-content-center align-items-center mb-3'
            style={{ minHeight: '' }}
          >
            <TextField
              size='small'
              sx={{
                width: '25vw',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0
              }}
              // FormHelperTextProps={{ style: { margin: '5 -30 0 0' } }}
              error={Boolean(reffNumberError)}
              // helperText={reffNumberError}

              type='text'
              value={reffNumber}
              onChange={e => {
                setReffNumber(e.target.value);
                setReffNumberError('');
              }}
              variant='outlined'
              label='Reference Number'
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  handleGetShipment();
                }
              }}
            />
            <Button
              variant='contained'
              sx={{
                // marginTop: '-48px',
                // marginRight: '70px',
                marginLeft: -2,
                height: '42px',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
              }}
              onClick={() => {
                handleGetShipment();
              }}
              // disabled={loadingShipment}
            >
              Track
            </Button>

            <div
              className='d-flex align-items-center'
              style={{ minWidth: '60px' }}
            >
              {loadingShipment && (
                <CircularProgress size={35} className='ms-2' />
              )}
            </div>
          </div>
          {recordError && (
            <div className='d-flex justify-content-center align-items-center mt-3'>
              <Alert
                sx={{ fontSize: 16, marginTop: 5, width: 'auto' }}
                severity='error'
              >
                {recordError}
              </Alert>
            </div>
          )}

          {shipmentData.length > 0 && (
            <MaterialReactTable
              enableRowActions={false}
              enableRowSelection={false}
              columns={tableCol}
              data={shipmentData}
              enableTopToolbar={false}
              enableExpanding
              autoResetExpanded={true}
              initialState={{
                density: 'compact'
              }}
              renderDetailPanel={({ row }) => handleViewShipments(row)}
              muiTableContainerProps={{
                // ref: tableContainerRef,
                sx: {
                  maxHeight: '70vh',
                  // minHeight: '70vh',
                  maxWidth: '90vw',
                  // height: '500px',
                  overflowX: 'scroll'
                }
              }}
              enablePagination={false}
            />
          )}
        </div>
      }
    </Box>
  );
}
